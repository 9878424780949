import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { SpringSystem, MathUtil } from 'rebound'
import { css, withTheme } from '@emotion/react'

const styles = props => css`
  @media screen and (max-width: 768px) {
    height: calc(100vh - 60px) !important;

    div:first-of-type {
      width: 100%;
    }
  }
`

class SpringScrollbars extends Component {
  constructor (props, ...rest) {
    super(props, ...rest)
    this.handleSpringUpdate = this.handleSpringUpdate.bind(this)
  }

  componentDidMount () {
    this.springSystem = new SpringSystem()
    this.spring = this.springSystem.createSpring()
    this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate })
  }

  componentWillUnmount () {
    this.springSystem.deregisterSpring(this.spring)
    this.springSystem.removeAllListeners()
    this.springSystem = undefined
    this.spring.destroy()
    this.spring = undefined
  }

  getScrollTop () {
    return this.refs.scrollbars.getScrollTop()
  }

  getScrollHeight () {
    return this.refs.scrollbars.getScrollHeight()
  }

  getHeight () {
    return this.refs.scrollbars.getHeight()
  }

  scrollTop (top) {
    const { scrollbars } = this.refs
    const scrollTop = scrollbars.getScrollTop()
    const scrollHeight = scrollbars.getScrollHeight()
    const val = MathUtil.mapValueInRange(
      top,
      0,
      scrollHeight,
      scrollHeight * 0.2,
      scrollHeight * 0.8
    )
    this.spring.setCurrentValue(scrollTop).setAtRest()
    this.spring.setEndValue(val)
  }

  handleSpringUpdate (spring) {
    const { scrollbars } = this.refs
    const val = spring.getCurrentValue()
    scrollbars.scrollTop(val)
  }

  renderThumb ({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: this.props.theme.scrollbar.color
    }
    return <div style={{ ...style, ...thumbStyle }} {...props} />
  }

  render () {
    console.log(this.props)
    return (
      <Scrollbars
        autoHide
        {...this.props}
        renderThumbVertical={this.renderThumb.bind(this)}
        css={styles}
        ref='scrollbars'
      />
    )
  }
}

export default withTheme(SpringScrollbars)
